import React, { useState } from 'react';
import axios from '../axiosConfig';
import { TextField, Button, MenuItem, Select, InputLabel, FormControl, Typography, Alert, Container, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

function CreateOrder() {
  const [orderId, setOrderId] = useState('');
  const [productId, setProductId] = useState('');
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [action, setAction] = useState('add');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleAction = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');

      const productData = {
        product_id: productId,
        product_name: productName,
        price: parseFloat(price),
      };

      await axios.patch(
        `/v1/orders/${orderId}`,
        action === 'add' ? productData : { product_id: productId },
        {
          params: { action },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessageType('success');
      setMessage(`Product ${action === 'add' ? 'added' : 'removed'} successfully!`);
    } catch (error) {
      setMessageType('error');
      setMessage('An error occurred while processing your request.');
    }
  };

  const createEmptyOrder = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/v1/orders', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      setOrderId(response.data.order_id);
      setMessageType('success');
      setMessage('Empty order created successfully!');
    } catch (error) {
      setMessageType('error');
      setMessage('An error occurred while creating the order.');
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">Manage Order</Typography>
        <form onSubmit={handleAction}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="orderId"
            label="Order ID"
            value={orderId}
            onChange={(e) => setOrderId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="productId"
            label="Product ID"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
          />
          {action === 'add' && (
            <>
              <TextField
                margin="normal"
                required
                fullWidth
                id="productName"
                label="Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="price"
                label="Price"
                type="number"
                step="0.01"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </>
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel id="action-label">Action</InputLabel>
            <Select
              labelId="action-label"
              id="action"
              value={action}
              label="Action"
              onChange={(e) => setAction(e.target.value)}
            >
              <MenuItem value="add">Add</MenuItem>
              <MenuItem value="remove">Remove</MenuItem>
            </Select>
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            {action === 'add' ? 'Add Product' : 'Remove Product'}
          </Button>
        </form>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 3, mb: 2 }}
          onClick={createEmptyOrder}
        >
          Create Empty Order
        </Button>
        {message && (
          <Alert severity={messageType} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default CreateOrder;
