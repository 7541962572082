import React, { useState } from 'react';
import axios from '../axiosConfig';
import { TextField, Button, Typography, Container, CssBaseline, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

function CreateUser() {
  const [userId, setUserId] = useState('');
  const [role, setRole] = useState(''); // New state for role
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const generateToken = async (userId, role) => {
    try {
      const response = await axios.post('/generate-token', { user_id: userId, role });
      const { token } = response.data;
      localStorage.setItem('token', token); // Store token in local storage
      localStorage.setItem('user_id', userId); // Store user ID in local storage
      return token;
    } catch (error) {
      console.error('Error fetching token:', error);
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await generateToken(userId, role);
      if (!token) {
        setMessageType('error');
        setMessage('Failed to generate token');
        return;
      }

      await axios.post('/v1/users', { user_id: userId }, {
        headers: {
          Authorization: `Bearer ${token}`, // Include token in headers
        }
      });

      setMessageType('success');
      setMessage('User created successfully!');
    } catch (error) {
      setMessageType('error');
      setMessage('An error occurred while creating the user.');
      console.error('Error:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h5">Create User</Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="userId"
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="role"
            label="Role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Create User
          </Button>
        </form>
        {message && (
          <Alert severity={messageType} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
      </Container>
    </ThemeProvider>
  );
}

export default CreateUser;
