import React, { useState, useEffect, useCallback } from 'react';
import axios from '../axiosConfig';
import {
  TextField,
  Button,
  Typography,
  Alert,
  Container,
  CssBaseline,
  CircularProgress,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

function ListOrders() {
  const [userId, setUserId] = useState('');
  const [state, setState] = useState('');
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');

      const params = {};
      if (userId) params.user = userId;
      if (state) params.state = state;

      const response = await axios.get('/v1/orders', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      // Group products under their respective orders
      const groupedOrders = response.data.reduce((acc, order) => {
        const { order_id, product_id, product_name, price, ...rest } = order;
        if (!acc[order_id]) {
          acc[order_id] = { ...rest, order_id, products: [] };
        }
        if (product_id) {
          acc[order_id].products.push({ product_id, product_name, price });
        }
        return acc;
      }, {});

      setOrders(Object.values(groupedOrders));
    } catch (error) {
      if (error.response) {
        setError(error.response.data.detail);
      } else if (error.request) {
        setError('No response received from server.');
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [userId, state]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="md">
        <Typography component="h1" variant="h5">List Orders</Typography>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            fetchOrders();
          }}
        >
          <TextField
            margin="normal"
            fullWidth
            id="userId"
            label="User ID"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            id="state"
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Fetch Orders
          </Button>
        </form>

        {loading && <CircularProgress />}
        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {orders.map((order) => (
            <Card key={order.order_id} sx={{ mt: 2 }}>
              <CardContent>
                <Typography variant="h6">Order ID: {order.order_id}</Typography>
                <Typography variant="body2">User ID: {order.user_id}</Typography>
                <Typography variant="body2">State: {order.state}</Typography>
                {order.products && order.products.length > 0 && (
                  <List>
                    {order.products.map((product) => (
                      <React.Fragment key={product.product_id}>
                        <ListItem>
                          <ListItemText
                            primary={`Product Name: ${product.product_name}`}
                            secondary={`Product ID: ${product.product_id} | Price: $${product.price}`}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </List>
                )}
              </CardContent>
            </Card>
          ))}
        </List>
      </Container>
    </ThemeProvider>
  );
}

export default ListOrders;
