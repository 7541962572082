import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CreateUser from './components/CreateUser';
import CreateOrder from './components/CreateOrder';
import ListOrders from './components/ListOrders';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Order Management
            </Typography>
            <Button color="inherit" component={Link} to="/create-user">
              Create User
            </Button>
            <Button color="inherit" component={Link} to="/create-order">
              Create Order
            </Button>
            <Button color="inherit" component={Link} to="/list-orders">
              List Orders
            </Button>
          </Toolbar>
        </AppBar>
        <Container sx={{ mt: 4 }}>
          <Routes>
            <Route path="/create-user" element={<CreateUser />} />
            <Route path="/create-order" element={<CreateOrder />} />
            <Route path="/list-orders" element={<ListOrders />} />
          </Routes>
        </Container>
      </Router>
    </ThemeProvider>
  );
}

export default App;
